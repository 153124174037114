import React, { useState, useContext, useEffect } from "react";
import "./ContactPage.css";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { FormContext } from "../../context/FormContext";
import { useTranslation } from "react-i18next";
import Buttons from "../../components/Buttons/Buttons";

const ContactPage = () => {
	const [name, setName] = useState("");
	const [company, setCompany] = useState("");
	const [address, setAddress] = useState("");
	const [nip, setNip] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");

	const [billingCompany, setBillingCompany] = useState("");
	const [billingAddress, setBillingAddress] = useState("");
	const [billingNip, setBillingNip] = useState("");

	const [IDSBillingCompany, setIDSBillingCompany] = useState("");
	const [IDSBillingAddress, setIDSBillingAddress] = useState("");
	const [IDSBillingNip, setIDSBillingNip] = useState("");

	const [errors, setErrors] = useState({});
	const [isBillingSameAsContact, setIsBillingSameAsContact] = useState(true);
	const [isBillingSameAsContactIDS, setIsBillingSameAsContactIDS] = useState(true);
	const [isBillingSameAsPayerIDS, setIsBillingSameAsPayerIDS] = useState(false);

	const { formData, updateFormData } = useContext(FormContext);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (formData) {
			const { contactForm, billingForm, IDSBillingForm } = formData;

			if (contactForm) {
				setName(contactForm.name || "");
				setCompany(contactForm.company || "");
				setAddress(contactForm.address || "");
				setNip(contactForm.nip || "");
				setPhone(contactForm.phone || "");
				setEmail(contactForm.email || "");
			}

			if (billingForm) {
				setBillingCompany(billingForm.billingCompany || "");
				setBillingAddress(billingForm.billingAddress || "");
				setBillingNip(billingForm.billingNip || "");
				setIsBillingSameAsContact(billingForm.isBillingSameAsContact ?? true);
			}

			if (IDSBillingForm) {
				setIDSBillingCompany(IDSBillingForm.IDSBillingCompany || "");
				setIDSBillingAddress(IDSBillingForm.IDSBillingAddress || "");
				setIDSBillingNip(IDSBillingForm.IDSBillingNip || "");
				setIsBillingSameAsContactIDS(IDSBillingForm.isBillingSameAsContactIDS ?? true);
				setIsBillingSameAsPayerIDS(IDSBillingForm.isBillingSameAsPayerIDS ?? false);
			}
		}
	}, [formData]);

	const handleButtonClick = () => {
		if (validateForm()) {
			const contactForm = {
				name,
				company,
				address,
				nip,
				phone,
				email,
			};

			const billingForm = {
				billingCompany: getBillingValue('billingCompany'),
				billingAddress: getBillingValue('billingAddress'),
				billingNip: getBillingValue('billingNip'),
				isBillingSameAsContact,
			};

			const IDSBillingForm = {
				IDSBillingCompany: getIDSBillingValue('IDSBillingCompany'),
				IDSBillingAddress: getIDSBillingValue('IDSBillingAddress'),
				IDSBillingNip: getIDSBillingValue('IDSBillingNip'),
				isBillingSameAsContactIDS,
				isBillingSameAsPayerIDS,
			};

			updateFormData({ contactForm, billingForm, IDSBillingForm });
		}
	};

	const getBillingValue = (field) => {
		if (isBillingSameAsContact && field === "billingCompany") {
			return company
		} else if (isBillingSameAsContact && field === "billingAddress") {
			return address
		} else if (isBillingSameAsContact && field === "billingNip") {
			return nip
		}

		if (field === "billingCompany") {
			return billingCompany;
		} else if(field === "billingAddress") {
			return billingAddress;
		} else if(field === "billingNip") {
			return billingNip;
		}
	};

	const getIDSBillingValue = (field) => {
		if (isBillingSameAsContactIDS) {
			if (field === "IDSBillingCompany") {
				return company
			} else if (field === "IDSBillingAddress") {
				return address
			} else if (field === "IDSBillingNip") {
				return nip
			}
		} else if (isBillingSameAsPayerIDS) {
			if (isBillingSameAsContact) {
				if (field === "IDSBillingCompany") {
					return company
				} else if (field === "IDSBillingAddress") {
					return address
				} else if (field === "IDSBillingNip") {
					return nip
				}
			} else {
				if (field === "IDSBillingCompany") {
					return billingCompany
				} else if (field === "IDSBillingAddress") {
					return billingAddress
				} else if (field === "IDSBillingNip") {
					return billingNip
				}
			}
		}

		if (field === "IDSBillingCompany") {
			return IDSBillingCompany;
		} else if(field === "IDSBillingAddress") {
			return IDSBillingAddress;
		} else if(field === "IDSBillingNip") {
			return IDSBillingNip;
		}
	};

	const handleInputChange = (event, setInput) => {
		const inputId = event.target.id;
		const value = event.target.value;

		setInput(value);

		if (inputId === "nip") {
			if (!isValidNip(value)) return;
		}

		if (inputId === "billingNip") {
			if (!isValidNip(value)) return;
		}

		if (inputId === "IDSBillingNip") {
			if (!isValidNip(value)) return;
		}

		if (inputId === "email") {
			if (!isValidEmail(value)) return;
		}	
		if (inputId === "phone") {
			if (!isValidPhone(value)) return;
		}

		setErrors((prevErrors) => ({
			...prevErrors,
			[inputId]: "",
		}));
	};

	const handleInputBlur = (event, setInput, name) => {
		const value = event.target.value;
		let inputId = event.target.id;
		let errorString = "";

		if (value === "") {
			errorString = t(`page.contact.validation.${inputId}.required`);
		} else if (inputId === "nip" || inputId === "billingNip" || inputId === "IDSBillingNip") {
			if (!isValidNip(value)) {
				errorString = t(`page.contact.validation.${inputId}.invalid`);
			}
		} else if (inputId === "email") {
			if (!isValidEmail(value)) {
				errorString = t(`page.contact.validation.${inputId}.invalid`);
			}
		} else if (name === "phone") {
			inputId = "phone";

			if (!isValidPhone(value)) {
				errorString = t(`page.contact.validation.phone.required`);
			}
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				[inputId]: "",
			}));
		}

		setErrors((prevErrors) => ({
			...prevErrors,
			[inputId]: errorString,
		}));
	};

	const validateForm = () => {
		const errors = {};

		if (!name) {
			errors.name = t("page.contact.validation.name.required");
		}

		if (!company) {
			errors.company = t("page.contact.validation.company.required");
		}

		if (!address) {
			errors.address = t("page.contact.validation.address.required");
		}

		if (!nip) {
			errors.nip = t("page.contact.validation.nip.required");
		} else if (!isValidNip(nip)) {
			errors.nip = t("page.contact.validation.nip.invalid");
		}

		if (!phone) {
			errors.phone = t("page.contact.validation.phone.required");
		} else if (!isValidPhone(phone)) {
			errors.phone = t("page.contact.validation.phone.required");
		}

		if (!email) {
			errors.email = t("page.contact.validation.email.required");
		} else if (!isValidEmail(email)) {
			errors.email = t("page.contact.validation.email.invalid");
		}

		if (!isBillingSameAsContact) {
			if (!billingCompany) {
				errors.billingCompany = t("page.contact.validation.billingCompany.required");
			}
			if (!billingAddress) {
				errors.billingAddress = t("page.contact.validation.billingAddress.required");
			}
			if (!billingNip) {
				errors.billingNip = t("page.contact.validation.billingNip.required");
			} else if (!isValidNip(billingNip)) {
				errors.billingNip = t("page.contact.validation.billingNip.invalid");
			}
		}

		if (!isBillingSameAsContactIDS && !isBillingSameAsPayerIDS) {
			if (!IDSBillingCompany) {
				errors.IDSBillingCompany = t("page.contact.validation.billingCompany.required");
			}
			if (!IDSBillingAddress) {
				errors.IDSBillingAddress = t("page.contact.validation.billingAddress.required");
			}
			if (!IDSBillingNip) {
				errors.IDSBillingNip = t("page.contact.validation.billingNip.required");
			} else if (!isValidNip(IDSBillingNip)) {
				errors.IDSBillingNip = t("page.contact.validation.billingNip.invalid");
			}
		}

		setErrors(errors);

		return Object.keys(errors).length === 0;
	};

	const isValidNip = (value) => {
		// validates only polish tax number
		let lang = i18n.language;
		let nipRegex = /^/;
		if (lang === 'pl') {
			nipRegex = /^[0-9]{10}$/;
		}
		return nipRegex.test(value);
	};

	const isValidEmail = (value) => {
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;
		return emailRegex.test(value);
	};
	const isValidPhone = (value) => {
		return value.replace(/\D/g, "").length > 7;
	};

	const handleBillingCheckboxChange = (event) => {
		setIsBillingSameAsContact(event.target.checked);
	};

	const handleBillingIDSCheckboxChange = (event, setter, otherSetter) => {
		const isChecked = event.target.checked;
		setter(isChecked);
		if (isChecked) {
			otherSetter(false);
		}
	};

	const renderBillingFields = () => {
		if (isBillingSameAsContact) return null;
		return (
			<>
				<div className={`input-box ${errors.billingCompany ? "error" : ""}`}>
					<label htmlFor="billingCompany">{t("page.contact.label.billingCompany")}</label>
					<input
						id="billingCompany"
						type="text"
						value={billingCompany}
						onChange={(event) => handleInputChange(event, setBillingCompany)}
						onBlur={(event) => handleInputBlur(event, setBillingCompany)}
					/>
					{errors.billingCompany && (
						<span className="error-message">{errors.billingCompany}</span>
					)}
				</div>

				<div className={`input-box ${errors.billingAddress ? "error" : ""}`}>
					<label htmlFor="billingAddress">{t("page.contact.label.billingAddress")}</label>
					<input
						id="billingAddress"
						type="text"
						placeholder={t("page.contact.placeholder.address")}
						value={billingAddress}
						onChange={(event) => handleInputChange(event, setBillingAddress)}
						onBlur={(event) => handleInputBlur(event, setBillingAddress)}
					/>
					{errors.billingAddress && (
						<span className="error-message">{errors.billingAddress}</span>
					)}
				</div>

				<div className={`input-box ${errors.billingNip ? "error" : ""}`}>
					<label htmlFor="billingNip">{t("page.contact.label.billingNip")}</label>
					<input
						maxLength={12}
						id="billingNip"
						placeholder={t("page.contact.placeholder.nip")}
						type="text"
						value={billingNip}
						onChange={(event) => handleInputChange(event, setBillingNip)}
						onBlur={(event) => handleInputBlur(event, setBillingNip)}
					/>
					{errors.billingNip && <span className="error-message">{errors.billingNip}</span>}
					<span style={{marginBottom: "2rem"}}/>
				</div>
			</>
		);
	}

	const renderBillingIDSFields = () => {
		if (isBillingSameAsContactIDS || isBillingSameAsPayerIDS) return null;
		return (
			<>
				<div className={`input-box ${errors.IDSBillingCompany ? "error" : ""}`}>
					<label htmlFor="IDSBillingCompany">{t("page.contact.label.billingCompany")}</label>
					<input
						id="IDSBillingCompany"
						type="text"
						value={IDSBillingCompany}
						onChange={(event) => handleInputChange(event, setIDSBillingCompany)}
						onBlur={(event) => handleInputBlur(event, setIDSBillingCompany)}
					/>
					{errors.IDSBillingCompany && (
						<span className="error-message">{errors.IDSBillingCompany}</span>
					)}
				</div>

				<div className={`input-box ${errors.IDSBillingAddress ? "error" : ""}`}>
					<label htmlFor="billingAddress">{t("page.contact.label.billingAddress")}</label>
					<input
						id="IDSBillingAddress"
						type="text"
						placeholder={t("page.contact.placeholder.address")}
						value={IDSBillingAddress}
						onChange={(event) => handleInputChange(event, setIDSBillingAddress)}
						onBlur={(event) => handleInputBlur(event, setIDSBillingAddress)}
					/>
					{errors.IDSBillingAddress && (
						<span className="error-message">{errors.IDSBillingAddress}</span>
					)}
				</div>

				<div className={`input-box ${errors.IDSBillingNip ? "error" : ""}`}>
					<label htmlFor="IDSBillingNip">{t("page.contact.label.billingNip")}</label>
					<input
						maxLength={12}
						id="IDSBillingNip"
						placeholder={t("page.contact.placeholder.nip")}
						type="text"
						value={IDSBillingNip}
						onChange={(event) => handleInputChange(event, setIDSBillingNip)}
						onBlur={(event) => handleInputBlur(event, setIDSBillingNip)}
					/>
					{errors.IDSBillingNip && <span className="error-message">{errors.IDSBillingNip}</span>}
					<span style={{marginBottom: "2rem"}}/>
				</div>
			</>
		);
	}

	return (
		<div>
			<h2>{t("page.contact.header.contact")}</h2>

			<div className={`input-box ${errors.name ? "error" : ""}`}>
				<label htmlFor="name">{t("page.contact.label.name")}</label>
				<input
					id="name"
					type="text"
					value={name}
					onChange={(event) => handleInputChange(event, setName)}
					onBlur={(event) => handleInputBlur(event, setName)}
					required
				/>
				{errors.name && <span className="error-message">{errors.name}</span>}
			</div>

			<div className={`input-box ${errors.company ? "error" : ""}`}>
				<label htmlFor="company">{t("page.contact.label.company")}</label>
				<input
					id="company"
					type="text"
					value={company}
					onChange={(event) => handleInputChange(event, setCompany)}
					onBlur={(event) => handleInputBlur(event, setCompany)}
				/>
				{errors.company && (
					<span className="error-message">{errors.company}</span>
				)}
			</div>

			<div className={`input-box ${errors.address ? "error" : ""}`}>
				<label htmlFor="address">{t("page.contact.label.address")}</label>
				<input
					id="address"
					type="text"
					placeholder={t("page.contact.placeholder.address")}
					value={address}
					onChange={(event) => handleInputChange(event, setAddress)}
					onBlur={(event) => handleInputBlur(event, setAddress)}
				/>
				{errors.address && (
					<span className="error-message">{errors.address}</span>
				)}
			</div>

			<div className={`input-box ${errors.nip ? "error" : ""}`}>
				<label htmlFor="nip">{t("page.contact.label.nip")}</label>
				<input
					maxLength={12}
					id="nip"
					type="text"
					value={nip}
					placeholder={t("page.contact.placeholder.nip")}
					onChange={(event) => handleInputChange(event, setNip)}
					onBlur={(event) => handleInputBlur(event, setNip)}
				/>
				{errors.nip && <span className="error-message">{errors.nip}</span>}
				<span style={{marginBottom: "2rem"}}/>
			</div>

			<div className={`input-box ${errors.email ? "error" : ""}`}>
				<label htmlFor="email">{t("page.contact.label.email")}</label>
				<input
					id="email"
					type="email"
					value={email}
					onChange={(event) => handleInputChange(event, setEmail)}
					onBlur={(event) => handleInputBlur(event, setEmail)}
				/>
				{errors.email && <span className="error-message">{errors.email}</span>}
			</div>

			<div className={`input-box ${errors.phone ? "error" : ""}`}>
				<label htmlFor="phone">{t("page.contact.label.phone")}</label>
				<PhoneInput
					id="phone"
					className={`select-phone-number ${errors.phone ? "error" : ""}`}
					regions={"europe"}
					country={"pl"}
					value={phone}
					onChange={(phone) => setPhone(phone)}
					onBlur={(event) => handleInputBlur(event, setPhone, "phone")}
				/>
				{errors.phone && <span className="error-message">{errors.phone}</span>}
			</div>

			<h2>{t("page.contact.header.billing")}</h2>

			{renderBillingFields()}

			<div className="checkbox-group">
				<label>
					<input
						type="checkbox"
						checked={isBillingSameAsContact}
						onChange={handleBillingCheckboxChange}
					/>
					{t("page.contact.label.billingSameAsContact")}
				</label>
			</div>

			<h2>{t("page.contact.header.billingIDS")}</h2>

			{renderBillingIDSFields()}

			<div className="checkbox-group">
				<label>
					<input
						type="checkbox"
						checked={isBillingSameAsContactIDS}
						onChange={(event) =>
							handleBillingIDSCheckboxChange(
								event,
								setIsBillingSameAsContactIDS,
								setIsBillingSameAsPayerIDS
							)
						}
					/>
					{t("page.contact.label.billingSameAsContact")}
				</label>
				<label>
					<input
						type="checkbox"
						checked={isBillingSameAsPayerIDS}
						onChange={(event) =>
							handleBillingIDSCheckboxChange(
								event,
								setIsBillingSameAsPayerIDS,
								setIsBillingSameAsContactIDS
							)
						}
					/>
					{t("page.contact.label.billingSameAsPayer")}
				</label>
					<Buttons
						validateForm={validateForm}
						handleButtonClick={handleButtonClick}
					/>
			</div>
		</div>
	);
};

export default ContactPage;
