const industries = [
	"other",
	"electronics",
	"metallurgy",
	"food",
	"shipbuilding",
	"construction",
	"power",
	"medicine",
	"mining",
	"chemical",
	"logistics",
	"aviation",
	"automotive",
	"economic",
	"printing",
	"light",
	"machinery",
	"fuel",
	"glass",
	"agriculture",
	"furniture",
	"trade",
	"public",
	"railway",
];

export default industries;
