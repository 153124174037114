import React, { createContext, useState } from "react";

const FormContext = createContext();

const FormProvider = ({ children }) => {
	const [formData, setFormData] = useState({
		contactForm: {},
		billingForm: {},
		IDSBillingForm: {},
		machinesForm: {},
		installationForm: {},
	});

	const updateFormData = (newData) => {
		setFormData((prevData) => ({ ...prevData, ...newData }));
	};

	return (
		<FormContext.Provider value={{ formData, updateFormData }}>
			{children}
		</FormContext.Provider>
	);
};

export { FormContext, FormProvider };
