import React, { useState, useContext, useEffect } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import "./InstallationPage.css";
import { FormContext } from "../../context/FormContext";
import { useTranslation } from "react-i18next";
import Buttons from "../../components/Buttons/Buttons";
import RadioButtonsGroup from "../../components/RadioButtons/RadioButtonsGroup";

import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OptionSelect from "../../components/Select/OptionSelect";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

function InstallationPage() {
	const [formState, setFormState] = useState({
		company: "",
		address: "",
		phone: "",
		name: "",
		comment: "",
		country: "",
		industry: "",
		installation: "",
		configuration: "",
		machinesTraining: "",
		webTraining: "",
		transport: "",
		machineInstallation: "",
		machineTransfer: "",
		paymentMethod: "",
		subscriptionCost: "",
		rentalPeriod: [],
		rentDateTo: null,
		rentDateFrom: null,
		rentFormattedDateTo: "",
		rentFormattedDateFrom: "",
		expectedDeliveryDate: "",
		rawExpectedDeliveryDate: null,
		rulesAccepted: "",
	});

	const { formData, updateFormData } = useContext(FormContext);
	const [errors, setErrors] = useState({});

	const { t } = useTranslation();

	const isFieldValid = (fieldName) => {
		return !errors[fieldName];
	};

	const validateForm = () => {
		const newErrors = {};

		const requiredFields = [
			"country",
			"address",
			"company",
			"name",
			"phone",
			"industry",
			"installation",
			"machineTransfer",
			"rawExpectedDeliveryDate",
			"rulesAccepted",
		];

		if (formState.country && formState.country !== "PL") {
			requiredFields.push(
				"configuration",
				"webTraining",
				"machinesTraining",
				"transport",
				"machineInstallation"
			);
		}
		if (formState.machineTransfer === "sale") {
			requiredFields.push("paymentMethod");
		}
		if (formState.machineTransfer === "rental") {
			requiredFields.push("rentDateTo", "rentDateFrom");
		}

		requiredFields.forEach((field) => {
			const fieldValue = formState[field];
			if (
				fieldValue === null ||
				fieldValue === undefined ||
				fieldValue === ""
			) {
				newErrors[field] = true;
			} else {
				newErrors[field] = false;
			}
		});

		if (!isValidPhone(formState.phone)) {
			newErrors["phone"] = true;
		}

		setErrors(newErrors);
		for (const key in newErrors) {
			if (newErrors[key]) {
				return false;
			}
		}

		return true;
	};

	const isValidPhone = (value) => {
		return value.replace(/\D/g, "").length > 7;
	};

	const switchInputError = (fieldName, value) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			[fieldName]: value,
		}));
	};

	useEffect(() => {
		if (formData && formData.installationForm) {
			const installationForm = formData.installationForm;
			setFormState({
				...formState,
				company: installationForm.company || "",
				address: installationForm.address || "",
				phone: installationForm.phone || "",
				name: installationForm.name || "",
				country: installationForm.country || "",
				industry: installationForm.industry || "",
				installation: installationForm.installation || "",
				configuration:
					typeof installationForm.configuration === "boolean"
						? installationForm.configuration
						: "",
				machinesTraining:
					typeof installationForm.machinesTraining === "boolean"
						? installationForm.machinesTraining
						: "",
				webTraining:
					typeof installationForm.webTraining === "boolean"
						? installationForm.webTraining
						: "",
				machineTransfer: installationForm.machineTransfer || "",
				transport: installationForm.transport || "",
				machineInstallation: installationForm.machineInstallation || "",
				rentalPeriod: installationForm.rentalPeriod || t("global.label.nodatafound"),
				rentDateFrom: installationForm.rentDateFrom || null,
				rentDateTo: installationForm.rentDateTo || null,
				paymentMethod: installationForm.paymentMethod || "",
				subscriptionCost: installationForm.subscriptionCost || "",
				expectedDeliveryDate: installationForm.expectedDeliveryDate || "",
				rawExpectedDeliveryDate:
					installationForm.rawExpectedDeliveryDate || null,
				comment: installationForm.comment || "",
				rulesAccepted: installationForm.rulesAccepted || "",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const handleButtonClick = () => {
		const installationForm = {
			...formState,
		};
		updateFormData({ installationForm });
	};

	const handleInputChange = (e) => {
		const { name, value, type, id } = e.target;
		let newValue = value;

		if (type === "radio" || name === "country" || name === "industry") {
			switchInputError(id || name, false);
		}

		if (value === "true" || value === "false") {
			newValue = value === "true";
		}

		setFormState({
			...formState,
			[name]: newValue,
		});
	};

	const handleInputBlur = (event) => {
		const { value, name } = event.target;
		let inputId = event.target.id;
		let isValid = true;

		if (value === "") {
			isValid = false;
		} else if (name === "phone" && !isValidPhone(value)) {
			isValid = false;
			inputId = "phone";
		}

		switchInputError(inputId, !isValid);
	};

	const handlePhoneBlur = (event) => {
		switchInputError("phone", !isValidPhone(event.target.value));
	};

	const handleCheckboxChange = (event) => {
		const newValue = !formState.rulesAccepted;
		switchInputError("rulesAccepted", !newValue);
		setFormState({
			...formState,
			"rulesAccepted": newValue,
		});
	};

	const getFormattedDate = (unformattedDate) => {
		const date = new Date(unformattedDate);
		const day = date.getDate().toString().padStart(2, "0");
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear().toString();
		return `${day}.${month}.${year}`;
	};

	const updateExpectedDeliveryDate = (data) => {
		switchInputError("rawExpectedDeliveryDate", false);
		const formattedDate = getFormattedDate(data);
		setFormState({
			...formState,
			rawExpectedDeliveryDate: data,
			expectedDeliveryDate: formattedDate,
		});
	};

	const updateRentalPeriodFromDate = (data) => {
		switchInputError("rentDateFrom", false);
		const formattedDate = getFormattedDate(data);
		setFormState({
			...formState,
			rentDateFrom: data,
			rentFormattedDateFrom: formattedDate,
			rentalPeriod: `${formattedDate} - ${formState.rentFormattedDateTo}`,
		});
	};

	const updateRentalPeriodToDate = (data) => {
		switchInputError("rentDateTo", false);
		const formattedDate = getFormattedDate(data);
		setFormState({
			...formState,
			rentDateTo: data,
			rentFormattedDateTo: formattedDate,
			rentalPeriod: `${formState.rentFormattedDateFrom} - ${formattedDate}`,
		});
	};

	const installationOptions = [
		{
			value: "new",
			label: t("page.installation.radio.new"),
		},
		{
			value: "reinstall",
			label: t("page.installation.radio.reinstall"),
		},
	];
	const transportOptions = [
		{
			value: "asd",
			label: t("page.installation.radio.asd"),
		},
		{
			value: "ownTransport",
			label: t("page.installation.radio.ownTransport"),
		},
	];
	const machineInstallationOptions = [
		{
			value: "asd",
			label: t("page.installation.radio.asd"),
		},
		{
			value: "ownInstallation",
			label: t("page.installation.radio.ownInstallation"),
		},
	];

	const machineTransferOptions = [
		{
			value: "sale",
			label: t("page.installation.radio.sale"),
		},
		{
			value: "rental",
			label: t("page.installation.radio.rental"),
		},
	];

	const paymentMethodOptions = [
		{
			value: "transfer",
			label: t("page.installation.radio.transfer"),
		},
		{
			value: "leasing",
			label: t("page.installation.radio.leasing"),
		},
	];

	return (
		<div className="installation-page">
			<section className="installation">
				<h2>{t("page.installation.header.installation")}</h2>
				<div className="installation-details">
					<OptionSelect
						label={t("page.installation.label.country")}
						placeholder={t("page.installation.placeholder.country")}
						value={formState.country}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						isValid={isFieldValid("country")}
						options="country"
						name="country"
					/>
					<div
						className={`input-box ${!isFieldValid("address") ? "error" : ""}`}
						style={{ marginBottom: "64px" }}
					>
						<label htmlFor="address">
							{t("page.installation.label.address")}
						</label>
						<input
							id="address"
							type="text"
							name="address"
							value={formState.address}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							placeholder={t("page.installation.placeholder.address")}
						/>
						{!isFieldValid("address") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>

					<div
						className={`input-box ${!isFieldValid("company") ? "error" : ""}`}
					>
						<label htmlFor="company">
							{t("page.installation.label.company")}
						</label>
						<input
							id="company"
							type="text"
							name="company"
							placeholder={t("page.installation.placeholder.company")}
							value={formState.company}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
						/>
						{!isFieldValid("company") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>
					<div className={`input-box ${!isFieldValid("name") ? "error" : ""}`}>
						<label htmlFor="name">{t("page.installation.label.name")}</label>
						<input
							placeholder={t("page.installation.placeholder.name")}
							id="name"
							type="text"
							name="name"
							value={formState.name}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
						/>
						{!isFieldValid("name") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>

					<div className={`input-box ${!isFieldValid("phone") ? "error" : ""}`}>
						<label htmlFor="phone">{t("page.installation.label.phone")}</label>

						<PhoneInput
							id="phone"
							className={`select-phone-number ${!isFieldValid("phone") ? "error" : ""
								}`}
							regions={"europe"}
							country={"pl"}
							value={formState.phone}
							onChange={(value) => {
								setFormState({
									...formState,
									phone: value,
								});
							}}
							onBlur={handlePhoneBlur}
						/>
						{!isFieldValid("phone") && (
							<span className="error-message">
								{t("page.installation.validation.global.required")}
							</span>
						)}
					</div>
					<OptionSelect
						label={t("page.installation.label.industry")}
						placeholder={t("page.installation.placeholder.industry")}
						value={formState.industry}
						name="industry"
						options="industry"
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						isValid={isFieldValid("industry")}
					/>
				</div>

				<div className="radio-group">
					<RadioButtonsGroup
						id="installation"
						label={t("page.installation.label.installation")}
						options={installationOptions}
						value={formState.installation}
						handleChange={handleInputChange}
						isValid={isFieldValid("installation")}
					/>
					{formState.country && formState.country !== "PL" && (
						<Box className="conditional-radio-group">
							<RadioButtonsGroup
								id="configuration"
								label={t("page.installation.label.configuration")}
								value={formState.configuration}
								handleChange={handleInputChange}
								isValid={isFieldValid("configuration")}
							/>
							<RadioButtonsGroup
								id="machinesTraining"
								label={t("page.installation.label.machinesTraining")}
								value={formState.machinesTraining}
								handleChange={handleInputChange}
								isValid={isFieldValid("machinesTraining")}
							/>
							<RadioButtonsGroup
								id="webTraining"
								label={t("page.installation.label.webTraining")}
								value={formState.webTraining}
								handleChange={handleInputChange}
								isValid={isFieldValid("webTraining")}
							/>
							<RadioButtonsGroup
								id="transport"
								label={t("page.installation.label.transport")}
								options={transportOptions}
								value={formState.transport}
								handleChange={handleInputChange}
								isValid={isFieldValid("transport")}
							/>
							<RadioButtonsGroup
								id="machineInstallation"
								label={t("page.installation.label.machineInstallation")}
								options={machineInstallationOptions}
								value={formState.machineInstallation}
								handleChange={handleInputChange}
								isValid={isFieldValid("machineInstallation")}
							/>
						</Box>
					)}
				</div>
			</section>
			<section className="transfer">
				<h2>{t("page.installation.header.transfer")}</h2>
				<div className="radio-group">
					<RadioButtonsGroup
						id="machineTransfer"
						options={machineTransferOptions}
						value={formState.machineTransfer}
						handleChange={handleInputChange}
						isValid={isFieldValid("machineTransfer")}
					/>
					{formState.machineTransfer === "rental" && (
						<Box className="range-calendar-box" mb={5}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<Box>
									<DatePicker
										label={t("page.installation.placeholder.dateFrom")}
										value={formState.rentDateFrom}
										onChange={(value) => updateRentalPeriodFromDate(value)}
										format="DD-MM-YYYY"
										disablePast
									/>
									{!isFieldValid("rentDateFrom") && (
										<Typography mt={2} className="error-message">
											{t("page.installation.validation.global.required")}
										</Typography>
									)}
								</Box>
								<Box>
									<DatePicker
										label={t("page.installation.placeholder.dateTo")}
										value={formState.rentDateTo}
										onChange={(value) => updateRentalPeriodToDate(value)}
										format="DD-MM-YYYY"
										disablePast
									/>
									{!isFieldValid("rentDateTo") && (
										<Typography mt={2} className="error-message">
											{t("page.installation.validation.global.required")}
										</Typography>
									)}
								</Box>
							</LocalizationProvider>
						</Box>
					)}
					{formState.machineTransfer === "sale" && (
						<RadioButtonsGroup
							id="paymentMethod"
							label={t("page.installation.label.paymentMethod")}
							options={paymentMethodOptions}
							value={formState.paymentMethod}
							handleChange={handleInputChange}
							isValid={isFieldValid("paymentMethod")}
						/>
					)}
				</div>
				<div className="transfer-details">
					<Box className="input-box" mb={5}>
						<label htmlFor="waitingTime">
							{t("page.installation.label.expectedDeliveryDate")}
						</label>
						<Box className="calendar-box">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label={t("page.installation.placeholder.date")}
									value={formState.rawExpectedDeliveryDate}
									onChange={(value) => updateExpectedDeliveryDate(value)}
									format="DD-MM-YYYY"
									disablePast
								/>
							</LocalizationProvider>
							<span className="side-text">
								/ {t("page.installation.tooltip.dateConfirmation")}
							</span>
						</Box>
						{!isFieldValid("rawExpectedDeliveryDate") && (
							<Typography className="date-error error-message">
								{t("page.installation.validation.global.required")}
							</Typography>
						)}
					</Box>

					<div className="input-box">
						<label htmlFor="comment">
							{t("page.installation.label.comment")}
						</label>
						<textarea
							name="comment"
							value={formState.comment}
							onChange={handleInputChange}
						/>
					</div>
					<section className="rules">
						<a
							href="https://asdsystems.eu/content/oferta"
							className="contract-link"
							target="_blank"
							rel="noreferrer"
						>
							<span>{t("global.btn.contract")}</span>
							<LinkIcon />
						</a>
						<div className="rules-box">
							<input
								type="checkbox"
								name="rulesAccepted"
								value={formState.rulesAccepted}
								checked={formState.rulesAccepted}
								onChange={handleCheckboxChange}
							/>
							<label name="rulesAccepted" onClick={handleCheckboxChange}>
								{t("page.installation.label.contractSubmit")}
							</label>
						</div>
						{!isFieldValid("rulesAccepted") && (
							<Typography mt={2} className="error-message">
								{t("page.installation.validation.global.required")}
							</Typography>
						)}
					</section>
				</div>
			</section>
			<Buttons
				validateForm={validateForm}
				handleButtonClick={handleButtonClick}
			></Buttons>
		</div>
	);
}

export default InstallationPage;
