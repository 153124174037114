import React, {createContext, useState} from "react";

export const DrumConfigurationContext = createContext();

export const DrumConfigurationProvider = ({ children }) => {
    const [drumConfigurationData, setDrumConfigurationData] = useState({
        columnsPerRow: [],
        mergedRows: [],
        mergedRowsLeft: [],
        mergedRowsRight: [],
        options: [],
        rows: 0,
        columns: 0,
    });

    const [numberOfCells, setNumberOfCells] = useState(0);

    const setDrumConfiguration = (newConfig) => {
        setNumberOfCells(calculateTotalCells(newConfig));
        setDrumConfigurationData(prevState => {
            return {...prevState, ...newConfig};
        });
    }

    const calculateTotalCells = (data) => {
        const columnsPerRow = data.columnsPerRow;

        if ('mergedRowsLeft' in data && 'mergedRowsRight' in data) {
            return columnsPerRow.reduce((total, numberOfColumns, rowIndex) => {
                if (data.mergedRowsLeft[rowIndex]) {
                    total--;
                }

                if (data.mergedRowsRight[rowIndex]) {
                    total--;
                }

                return total + numberOfColumns;
            }, 0);
        } else {
            let mergedRows = data.mergedRows;

            return columnsPerRow.reduce((total, numberOfColumns, rowIndex) => {
                if (mergedRows[rowIndex]) {
                    return total;
                } else {
                    return total + numberOfColumns;
                }
            }, 0);
        }
    };

    return (
        <DrumConfigurationContext.Provider value={{
            ...drumConfigurationData,
            setDrumConfiguration,
            numberOfCells
        }
        }>
            {children}
        </DrumConfigurationContext.Provider>
    )
}
