import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Import the i18n configuration file
import App from "./App";
import { AppProvider } from "./context/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<I18nextProvider i18n={i18n}>
		<AppProvider>
			<App />
		</AppProvider>
	</I18nextProvider>
);
