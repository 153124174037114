import React, { useContext } from "react";
import "./Buttons.css";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import GeneratePDFButton from "./GeneratePDFButton";
import { scrollToTop } from '../../utility/smoothScroll'; 

const Buttons = ({ handleButtonClick, validateForm }) => {
	const { currentPage, handlePageChange } = useContext(AppContext);
	const { t } = useTranslation();

	const handleClick = (page) => {
		if (process.env.NODE_ENV === "production") {
			if (!validateForm()) {
				scrollToTop();
				return;
			}
		}

		window.scrollTo({ top: 0, left: 0 });
		handlePageChange(page);
		handleButtonClick();
	};

	if (currentPage === 0) {
		return (
			<div className="btn-box no-back-btn">
				<button
					type="button"
					className="btn-next"
					onClick={() => handleClick(currentPage + 1)}
				>
					{t("global.btn.next")}
				</button>
			</div>
		);
	}

	return (
		<div className="btn-box">
			<button
				type="button"
				className="btn-back"
				onClick={() => handleClick(currentPage - 1)}
			>
				{t("global.btn.back")}
			</button>
			{currentPage === 1 && (
				<button
					type="button"
					className="btn-next"
					onClick={() => handleClick(currentPage + 1)}
				>
					{t("global.btn.next")}
				</button>
			)}
			{currentPage === 2 && (
				<GeneratePDFButton
					validateForm={validateForm}
					handleButtonClick={handleButtonClick}
				/>
			)}
		</div>
	);
};

export default Buttons;
