import React, { useContext } from "react";
import "./Navbar.css";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";

const Navigation = () => {
	const { currentPage, handlePageChange } = useContext(AppContext);
	const { t } = useTranslation();

	const handleButtonClick = (index) => {
		if (currentPage > index) {
			handlePageChange(index);
		}
	};

	return (
		<nav>
			<ul>
				<li>
					<button
						className={`circle-button ${
							currentPage === 0 ? "active" : "completed"
						}`}
						onClick={() => handleButtonClick(0)}
					>
						<div className="circle">
							{currentPage < 1 ? "1" : <CheckIcon />}
						</div>
						<div className="label">{t("navbar.contactPage")}</div>
					</button>
				</li>
				<div className="nav-line">
					<hr></hr>
				</div>
				<li>
					<button
						className={`circle-button ${currentPage === 1 ? "active" : ""} ${
							currentPage > 1 ? "completed" : ""
						}`}
						onClick={() => handleButtonClick(1)}
					>
						<div className="circle">
							{currentPage < 2 ? "2" : <CheckIcon />}
						</div>
						<div className="label">{t("navbar.machinesPage")}</div>
					</button>
				</li>
				<div className="nav-line">
					<hr></hr>
				</div>
				<li>
					<button
						className={`circle-button ${currentPage === 2 ? "active" : ""} ${
							currentPage > 2 ? "completed" : ""
						}`}
						onClick={() => handleButtonClick(2)}
					>
						<div className="circle">
							{currentPage < 3 ? "3" : <CheckIcon />}
						</div>
						<div className="label">{t("navbar.installationPage")}</div>
					</button>
				</li>
			</ul>
		</nav>
	);
};

export default Navigation;
