import React from "react";
import DrumConfiguratorPage from "../../DrumConfiguratorPage/DrumConfiguratorPage";
import { useTranslation } from "react-i18next";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";

const DrumConfigurationModal = ({
	isPopupOpen,
	machineType,
	numberOfCells,
	fullConfiguration,
	handleClosePopup,
}) => {

	const { t } = useTranslation();

	if (!isPopupOpen) {
		return null;
	}

	return (
		<div
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{
					borderBottom: "1px solid #D9D9D9",
					width: "80%",
					padding: "10px 40px",
					backgroundColor: "#F7F8F9",
					borderTopLeftRadius: "10px",
					borderTopRightRadius: "10px",
					margin: "0",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<h3>{t("modals.configurator.title")}</h3>
			</div>
			<div
				style={{
					backgroundColor: "white",
					padding: "0 40px 40px 40px",
					position: "relative",
					width: "80%",
					height: "80%",
					borderBottomLeftRadius: "10px",
					borderBottomRightRadius: "10px",
					overflow: "auto",
				}}
			>
				<div
					style={{
						display: "flex",
						marginLeft: "auto",
						marginRight: "auto",
						width: "100%",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "start",
							width: "33%",
						}}
					>
						<img
							style={{ marginRight: "10px", height: "85px" }}
							src={process.env.PUBLIC_URL + "/" + machineType + ".png"}
							alt="machine"
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "middle",
							}}
						>
							<p style={{ color: "#999999", margin: "0" }}>
								{t("modals.configurator.model")}
							</p>
							<h3 style={{ margin: "0" }}>{machineType}</h3>
						</div>
					</div>
					<div
						style={{
							width: "33%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<p
							style={{
								color: "#999999",
								textAlign: "center",
								margin: "10px",
							}}
						>
							{t("modals.configurator.cellAmount")}: <b>{numberOfCells}</b>
						</p>
						<button
							onClick={fullConfiguration}
							className="btn-back"
							style={{ padding: "3px", fontSize: "12px" }}
						>
							{t("modals.configurator.fullConfiguration")}
						</button>
					</div>
					<div
						style={{
							width: "33%",
							display: "flex",
							alignItems: "center",
							justifyContent: "end",
						}}
					>
						{/*<button*/}
						{/*	onClick={handleClosePopup}*/}
						{/*	className="btn-back"*/}
						{/*	style={{*/}
						{/*		width: "100px",*/}
						{/*		color: "black",*/}
						{/*		borderColor: "#99999999",*/}
						{/*	}}*/}
						{/*>*/}
						{/*	Anuluj*/}
						{/*</button>*/}
						<button
							onClick={handleClosePopup}
							className="btn-next"
							style={{ width: "200px", marginLeft: "30px" }}
						>
							{t("global.btn.save")}
						</button>
					</div>
				</div>
				<DrumConfiguratorPage />
				<div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
					<div className="primary-info">
						<InfoIcon />
						{t("modals.configurator.mergeCellsKey")}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DrumConfigurationModal;
