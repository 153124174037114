const countries = [
	{ code: "AL", name: "Albania" },
	{ code: "AD", name: "Andorra" },
	{ code: "AT", name: "Austria" },
	{ code: "BY", name: "Belarus" },
	{ code: "BE", name: "Belgium" },
	{ code: "BA", name: "Bosnia and Herzegovina" },
	{ code: "BG", name: "Bulgaria" },
	{ code: "HR", name: "Croatia" },
	{ code: "CY", name: "Cyprus" },
	{ code: "CZ", name: "Czech Republic" },
	{ code: "DK", name: "Denmark" },
	{ code: "EE", name: "Estonia" },
	{ code: "FO", name: "Faroe Islands" },
	{ code: "FI", name: "Finland" },
	{ code: "FR", name: "France" },
	{ code: "DE", name: "Germany" },
	{ code: "GI", name: "Gibraltar" },
	{ code: "GR", name: "Greece" },
	{ code: "HU", name: "Hungary" },
	{ code: "IS", name: "Iceland" },
	{ code: "IE", name: "Ireland" },
	{ code: "IT", name: "Italy" },
	{ code: "LV", name: "Latvia" },
	{ code: "LI", name: "Liechtenstein" },
	{ code: "LT", name: "Lithuania" },
	{ code: "LU", name: "Luxembourg" },
	{ code: "MK", name: "North Macedonia" },
	{ code: "MT", name: "Malta" },
	{ code: "MD", name: "Moldova" },
	{ code: "MC", name: "Monaco" },
	{ code: "ME", name: "Montenegro" },
	{ code: "NL", name: "Netherlands" },
	{ code: "NO", name: "Norway" },
	{ code: "PL", name: "Poland" },
	{ code: "PT", name: "Portugal" },
	{ code: "RO", name: "Romania" },
	{ code: "RU", name: "Russia" },
	{ code: "SM", name: "San Marino" },
	{ code: "RS", name: "Serbia" },
	{ code: "SK", name: "Slovakia" },
	{ code: "SI", name: "Slovenia" },
	{ code: "ES", name: "Spain" },
	{ code: "SE", name: "Sweden" },
	{ code: "CH", name: "Switzerland" },
	{ code: "UA", name: "Ukraine" },
	{ code: "GB", name: "United Kingdom" },
	{ code: "VA", name: "Vatican City" },
];

export default countries;
