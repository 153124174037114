export const scrollToTop = () => {
	const currentScrollY = window.scrollY;
	const targetScrollY = 0;
	const scrollDifference = targetScrollY - currentScrollY;
	const duration = 500;

	let startTime;

	function scrollAnimation(timestamp) {
		if (!startTime) {
			startTime = timestamp;
		}

		const timeElapsed = timestamp - startTime;

		const nextScrollY = easeInOutQuad(
			timeElapsed,
			currentScrollY,
			scrollDifference,
			duration
		);

		window.scrollTo(0, nextScrollY);

		if (timeElapsed < duration) {
			requestAnimationFrame(scrollAnimation);
		}
	}

	requestAnimationFrame(scrollAnimation);
};

const easeInOutQuad = (t, b, c, d) => {
	t /= d / 2;
	if (t < 1) return (c / 2) * t * t + b;
	t--;
	return (-c / 2) * (t * (t - 2) - 1) + b;
};
