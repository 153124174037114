import * as React from "react";
import { Radio, RadioGroup, FormControlLabel, FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./RadioButtons.css";

const defaultOptions = [
	{ value: true, label: "global.label.yes" },
	{ value: false, label: "global.label.no" },
];

const RadioButtonsGroup = ({
	id,
	label,
	options = defaultOptions,
	value,
	handleChange,
	groupSize = 2,
	tooltip = false,
	isValid=true
}) => {
	const { t } = useTranslation();

	return (
		<div className={!tooltip ? "input-box" : ""}>
			{label ? <FormLabel>{label}</FormLabel> : ""}
			<RadioGroup
				row
				aria-label={label}
				value={value}
				onChange={handleChange}
			>
				{options.slice(0, groupSize).map((option) => (
					<FormControlLabel
						className="radio-label"
						key={option.value}
						value={option.value}
						control={
							<Radio
								name={id}
								id={id}
								sx={{
									color: !isValid ? "#AF3732" : "",
								}}
							/>
						}
						label={options === defaultOptions ? t(option.label) : option.label}
					/>
				))}
			</RadioGroup>
			{!isValid && (
				<span className="error-message">
					{t("global.validation.radio.required")}
				</span>
			)}
		</div>
	);
};

export default RadioButtonsGroup;
