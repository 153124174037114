import React, { createContext, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
	const [currentPage, setCurrentPage] = useState(0);

	const handlePageChange = (index) => {
		setCurrentPage(index);
	};

	return (
		<AppContext.Provider value={{ currentPage, handlePageChange }}>
			{children}
		</AppContext.Provider>
	);
};

export { AppContext, AppProvider };
