import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import countries from "../../countries";
import industries from "../../assets/industries";

const styles = {
	scrollableList: {
		maxHeight: "350px",
		overflowY: "auto",
	},
};

export default function OptionSelect({
	label,
	placeholder,
	value,
	options,
	onChange,
	isValid = true,
	name
}) {
	const { t } = useTranslation();

	return (
		<div className="input-box">
			<label htmlFor={options}>{label}</label>
			<FormControl fullWidth size="small">
				<Select
					name={name}
					error={!isValid}
					labelId="option-select-label"
					id="option-select"
					value={value}
					onChange={onChange}
					displayEmpty
					MenuProps={{
						getContentAnchorEl: null,
						PaperProps: {
							style: styles.scrollableList,
						},
					}}
					style={styles.select}
				>
					<MenuItem value="" disabled selected>
						{placeholder}
					</MenuItem>
					{options === "country" &&
						countries.map((country, index) => (
							<MenuItem key={index} value={country.code}>
								{t(`countries.${country.code}`)}
							</MenuItem>
						))}
					{options === "industry" &&
						industries.map((industry, index) => (
							<MenuItem key={index} value={industry}>
								{t(`industries.${industry}`)}
							</MenuItem>
						))}
				</Select>
				{!isValid && (
					<span className="error-message">
						{t("page.installation.validation.global.required")}
					</span>
				)}
			</FormControl>
		</div>
	);
}
