import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import download from 'downloadjs';

export const generatePDF = async (drumConfiguration, formData, translations) => {
    const { rows, columns, columnsPerRow, mergedRows, mergedRowsLeft, mergedRowsRight } = drumConfiguration;
    const { contactForm, machinesForm, installationForm, billingForm, IDSBillingForm } = formData;
    const pdfDoc = await PDFDocument.create();
    const fontBytes = await fetch('/Montserrat-Regular.ttf').then((response) => response.arrayBuffer());
    const fontBytesBold = await fetch('/Montserrat-Bold.ttf').then((response) => response.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFontRegular = await pdfDoc.embedFont(fontBytes);
    const customFontBold = await pdfDoc.embedFont(fontBytesBold);

    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    const fontSize = 7;

    const pagePadding = 30; // padding on all sides of the page
    const tableHeight = (height - 2 * pagePadding) * 0.4; // table takes up 40% of the page height
    const cellHeight = tableHeight / (rows + 2); // +1 for the header row

    page.setFont(customFontRegular);

    const drawCellWithNoBottomBorder = ({ x, y, width, height }, page) => {
        const rightX = x + width;

        page.drawLine({
            start: { x, y },
            end: { x, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x: rightX, y },
            end: { x: rightX, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x: rightX, y: y + height },
            end: { x, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });
    };

    const drawCellWithNoTopBorder = ({ x, y, width, height }, page) => {
        const rightX = x + width;

        page.drawLine({
            start: { x, y },
            end: { x: rightX, y },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x, y },
            end: { x, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x: rightX, y: y + height },
            end: { x: rightX, y },
            thickness: 1,
            color: rgb(0, 0, 0),
        });
    };

    const drawCellWithNoTopBottomBorder = ({ x, y, width, height }, page) => {
        const rightX = x + width;

        page.drawLine({
            start: { x, y },
            end: { x, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x: rightX, y },
            end: { x: rightX, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });
    };

    const drawLeftCell = ({ x, y, width, height }, mergedAbove, mergedBelow, page) => {
        const rightX = x + width;

        page.drawLine({
            start: { x, y },
            end: { x, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        if (!mergedBelow) {
            page.drawLine({
                start: { x: rightX, y },
                end: { x: rightX, y: y + height },
                thickness: 1,
                color: rgb(0, 0, 0),
            });
        }

        if (!mergedAbove) {
            page.drawLine({
                start: { x: rightX, y: y + height },
                end: { x, y: y + (mergedAbove ? 0 : height) },
                thickness: mergedAbove ? 0 : 1,
                color: rgb(0, 0, 0),
            });
        }
    };

    const drawRightCell = ({ x, y, width, height }, mergedAbove, mergedBelow, isLastCell, page) => {
        const rightX = x + width;

        if (!mergedAbove) {
            page.drawLine({
                start: { x, y },
                end: { x, y: y + height },
                thickness: 1,
                color: rgb(0, 0, 0),
            });
        }

        page.drawLine({
            start: { x: rightX, y },
            end: { x: rightX, y: y + height },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        if (isLastCell) {
            page.drawLine({
                start: { x: pagePadding, y: currentY },
                end: { x: rightX, y: currentY },
                thickness: 1,
                color: rgb(0, 0, 0),
            });
        }

        if (!mergedBelow) {
            page.drawLine({
                start: { x: rightX, y: y + height },
                end: { x, y: y + height },
                thickness: 1,
                color: rgb(0, 0, 0),
            });
        }

    };


    // const drawDrumConfiguration = (page) => {

    // }



    function getTranslation(path, key) {
        if (key === "") {
            key = "nodatafound";
        } else if (!key) {
            key = "false";
        }

        let pathElements = path.split('.');

        let obj = translations;
        for (let element of pathElements) {
            obj = obj[element];
        }

        return obj[key] || obj['nodatafound'];
    }

    const forms = {
        'contact': contactForm,
        'billing': billingForm,
        'IDSBilling': IDSBillingForm,
        'machines': machinesForm,
        'installation': installationForm
    }

    const translatableFields = ['color', 'barcodeReader', 'drumScanner', 'logoOnDisplay', 'internetConnection', 'networkType', 'preferredLogin', 'cardReaderType', 'installation', 'configuration', 'machinesTraining', 'webTraining', 'transport', 'machineInstallation', 'machineTransfer', 'paymentMethod', 'country', 'industry'];

    const formFields = {
        'contact': ['name', 'company', 'address', 'nip', 'email', 'phone'],
        'billing': ['billingCompany', 'billingAddress', 'billingNip'],
        'IDSBilling': ['IDSBillingCompany', 'IDSBillingAddress', 'IDSBillingNip'],
        'machines': ['machineType', 'version', 'barcodeReader', 'drumScanner', 'internetConnection', 'networkType', 'preferredLogin', 'cardReaderType', 'contactPerson', 'emailAddress', 'contactPersonPhone', 'logoOnDisplay', 'color', 'rightDoorDetail', 'leftDoorDetail', 'rightCaseDetail', 'leftCaseDetail', 'backCaseDetail', 'topCaseDetail'],
        'installation': ['country', 'address', 'company', 'name', 'phone', 'industry', 'installation', 'configuration', 'machinesTraining', 'webTraining', 'transport', 'machineInstallation', 'machineTransfer', 'paymentMethod', 'rentalPeriod', 'expectedDeliveryDate']
    }

    const fields = {};

    Object.keys(forms).forEach(formName => {
        const form = forms[formName];
        const fieldNames = formFields[formName];

        fields[formName] = [...fieldNames.map(fieldName => {
            if (translatableFields.includes(fieldName)) {
                return {
                    label: translations[formName].label[fieldName],
                    value: getTranslation(`${formName}.data`, form[fieldName]),
                    translationKey: `${formName}.label.${fieldName}`
                };
            } else {
                return {
                    label: translations[formName].label[fieldName],
                    value: form[fieldName],
                    translationKey: `${formName}.label.${fieldName}`
                };
            }
        })];
    });

    const drawEndOfTable = (page, startY, pageWidth, tableStartY) => {
        // vertical line
        page.drawLine({
            start: { x: pageWidth / 2, y: startY + 10 },
            end: { x: pageWidth / 2, y: startY - 5 },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        const tableEndY = startY - 5;

        // left line
        page.drawLine({
            start: { x: pagePadding, y: tableStartY },
            end: { x: pagePadding, y: tableEndY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        // right line
        page.drawLine({
            start: { x: pageWidth - pagePadding, y: tableStartY },
            end: { x: pageWidth - pagePadding, y: tableEndY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });
    };

    const drawField = (page, formStartY, cellHeight, pageWidth, field) => {
        let startY = formStartY - cellHeight;
        page.setFont(customFontBold);
        page.drawText(`${field.label}: `, { x: pagePadding + 2, y: startY, color: rgb(0, 0, 0) });
        page.setFont(customFontRegular);
        page.drawText(field.value, { x: (pageWidth / 2) + 2, y: startY, color: rgb(0, 0, 0) });
        page.drawLine({
            start: { x: pagePadding, y: startY - 5 },
            end: { x: pageWidth - pagePadding, y: startY - 5 },
            thickness: 1,
            color: rgb(0, 0, 0),
        });
        page.drawLine({
            start: { x: pageWidth / 2, y: startY + 10 },
            end: { x: pageWidth / 2, y: startY - 5 },
            thickness: 1,
            color: rgb(0, 0, 0),
        });
        return startY;
    }

    const drawFieldsForForm = (formName, page, formStartY, cellHeight, pageWidth, fields) => {
        // section header
        page.setFont(customFontBold);
        let headerWidth = `${translations[formName].header}`.length * (fontSize / 2);
        let startX = (width / 2) - headerWidth / 2;
        page.setFontSize(fontSize);
        page.drawText(`${translations[formName].header}`, { x: startX, y: formStartY, color: rgb(0, 0, 0) });

        page.setFont(customFontBold);
        let startY = formStartY - cellHeight;
        let tableStartY = startY + 10;

        // Top line
        page.drawLine({
            start: { x: pagePadding, y: tableStartY },
            end: { x: pageWidth - pagePadding, y: tableStartY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        fields[formName].forEach((field) => {
            startY = drawField(page, formStartY, cellHeight, pageWidth, field);
            formStartY = startY;
        });

        drawEndOfTable(page, startY, pageWidth, tableStartY);
        return startY;
    }

    // let formStartY = currentY - cellHeight * 2;
    let formStartY = height - pagePadding;
    formStartY = drawFieldsForForm('contact', page, formStartY, cellHeight, width, fields);
    formStartY = drawFieldsForForm('billing', page, formStartY - 15, cellHeight, width, fields);
    formStartY = drawFieldsForForm('IDSBilling', page, formStartY - 15, cellHeight, width, fields);
    formStartY = drawFieldsForForm('machines', page, formStartY - 15, cellHeight, width, fields);
    drawFieldsForForm('installation', page, formStartY - 15, cellHeight, width, fields);

    const page2 = pdfDoc.addPage();
    page2.setFontSize(5);
    formStartY = height - pagePadding - cellHeight * 2;

    const grayScale = 0.4;
    const grayScaleFont = 0.8;
    // Draw header row

    let currentY;

    if (machinesForm["machineType"] !== "F80") {
			for (let i = 0; i < columns; i++) {
				if (columns !== 2) {
					const cellX = pagePadding + ((width - 2 * pagePadding) / columns) * i;

					page2.drawText(`${i + 1}`, {
						x: cellX + 1.5,
						y: height - pagePadding - cellHeight + 3,
						color: rgb(grayScale, grayScale, grayScale),
					});

					// Draw cell border for header
					const cell = {
						x: cellX,
						y: height - pagePadding - cellHeight,
						width: (width - 2 * pagePadding) / columns,
						height: cellHeight / 2,
					};
					page2.drawRectangle({
						...cell,
						borderColor: rgb(grayScaleFont, grayScaleFont, grayScaleFont),
						borderWidth: 1,
					});
				}
			}

			// Draw row indexes
			for (let i = 0; i < rows; i++) {
				const currentY = height - pagePadding - cellHeight * (i + 2) + 3;
				page2.drawText(`${i + 1}`, {
					x: pagePadding - 8, // Adjust this value as needed
					y: currentY + 3,
					color: rgb(grayScale, grayScale, grayScale),
				});

				// Draw cell border for row index
				const cell = {
					x: pagePadding - 10, // Adjust these values as needed
					y: currentY - 3,
					width: 10,
					height: cellHeight,
				};
				page2.drawRectangle({
					...cell,
					borderColor: rgb(grayScaleFont, grayScaleFont, grayScaleFont),
					borderWidth: 1,
				});
			}

			// Draw each row
			currentY = height - pagePadding - cellHeight * 2;
			for (let i = 0; i < rows; i++) {
				let numCols = columnsPerRow[i];
				if (i > 0) {
					numCols = mergedRows[i] ? columnsPerRow[i + 1] : columnsPerRow[i];
				}

				for (let j = 0; j < numCols; j++) {
					const cellWidthCurrent = (width - 2 * pagePadding) / numCols;
					const cell = {
						x: pagePadding + cellWidthCurrent * j,
						y: currentY,
						width: cellWidthCurrent,
						height: cellHeight,
					};
					const isLastCell = i === rows - 1 && j === numCols - 1 && j % 2 === 1;

					if (columns === 2) {
						if (j === 0) {
							// left column
							drawLeftCell(
								cell,
								mergedRowsLeft[i - 1],
								mergedRowsLeft[i],
								page2
							);
						} else if (j === 1) {
							// right column
							drawRightCell(
								cell,
								mergedRowsRight[i - 1],
								mergedRowsRight[i],
								isLastCell,
								page2
							);
						}
					} else {
						if (mergedRows[i]) {
							if (mergedRows[i - 1]) {
								drawCellWithNoTopBottomBorder(cell, page2);
							} else {
								drawCellWithNoBottomBorder(cell, page2);
							}
						} else if (mergedRows[i - 1]) {
							drawCellWithNoTopBorder(cell, page2);
						} else {
							page2.drawRectangle({
								...cell,
								borderColor: rgb(0, 0, 0),
								borderWidth: 1,
							});
						}
					}
				}
				currentY -= cellHeight;
			}
		} else {
			currentY = height - pagePadding - cellHeight * 2;
		}

    if (forms.installation["comment"]) {
        page2.setFont(customFontBold);
        page2.drawText(translations.installation.label.comment + ": ", {
            x: pagePadding,
            y: currentY,
        });
        currentY -= 10;
        page2.setFont(customFontRegular);
        page2.drawText(forms.installation["comment"], {
            x: pagePadding,
            y: currentY,
        });
    }

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, "Order Summary.pdf", "application/pdf");
};
