import React, { useContext } from 'react';
import './DrumConfiguratorPage.css';
import { DrumConfigurationContext } from "../../context/DrumConfigurationContext";
import { useTranslation } from "react-i18next";

const DrumConfiguratorPage = () => {
    const {
        columnsPerRow,
        mergedRows,
        mergedRowsLeft,
        mergedRowsRight,
        rows,
        columns,
        setDrumConfiguration,
        options
    } = useContext(DrumConfigurationContext);
    const defaultColumns = Array(rows).fill(columns);

    const { t } = useTranslation();

    const handleCheckboxChange = (rowIndex, side, event) => {
        const newColumnsPerRow = [...columnsPerRow];
        const newMergedRowsLeft = [...mergedRowsLeft];
        const newMergedRowsRight = [...mergedRowsRight];
        const isChecked = event.target.checked;

        if (isChecked) {
            newColumnsPerRow[rowIndex] = 2;
            if (side === 'left' && newMergedRowsLeft[rowIndex]) newMergedRowsLeft[rowIndex] = false;
            if (side === 'right' && newMergedRowsRight[rowIndex]) newMergedRowsRight[rowIndex] = false;
        } else {
            if (side === 'left') newMergedRowsLeft[rowIndex] = true;
            if (side === 'right') newMergedRowsRight[rowIndex] = true;
        }

        setDrumConfiguration({
            columnsPerRow: newColumnsPerRow,
            mergedRowsLeft: newMergedRowsLeft,
            mergedRowsRight: newMergedRowsRight
        })
    };

    const handleSelectChange = (rowIndex, event) => {
        const newColumnsPerRow = [...columnsPerRow];
        const newMergedRows = [...mergedRows];
        const newValue = event.target.value;

        if (newValue === 'X') {
            newMergedRows[rowIndex] = true;
            if (rowIndex >= 0 && rowIndex < rows - 1) {
                let i = rowIndex;
                while (newMergedRows[i] && rowIndex < rows) {
                    newColumnsPerRow[i + 1] = newColumnsPerRow[rowIndex];
                    i++;
                }
            }
        } else {
            newColumnsPerRow[rowIndex] = isNaN(Number(newValue)) ? 60 : Number(newValue);
            newMergedRows[rowIndex] = false;
        }

        setDrumConfiguration({
            columnsPerRow: newColumnsPerRow,
            mergedRows: newMergedRows
        })
    };

    return (
        <div className="main-container">
            {columns === 2 && (
                <div className="select-container" style={{ marginTop: '8px' }}>
                    {columnsPerRow.map((numberOfColumns, rowIndex) => (
                        <div key={`select-row-${rowIndex}-${numberOfColumns}`}  className="select-row" style={{ height: 'calc(100%/' + (defaultColumns.length + 0.48) + ')' }}>
                            {rowIndex < 19 &&
                                <input
                                    type="checkbox"
                                    checked={!mergedRowsLeft[rowIndex]}
                                    onChange={(event) => handleCheckboxChange(rowIndex, 'left', event)}
                                />
                            }
                        </div>
                    ))}
                </div>
            )}
            <div className={columns === 2 ? 'small-table-container' : 'table-container'}>
                {columns !== 2 &&
                    <div className="header-row">
                        <div className="header-cell">#</div>
                        {Array.from({ length: columns }).map((_, index) => (
                            <div key={`header-cell-${index}`} className="header-cell" style={{ width: 'calc(100% / ' + columns + ')' }}>{index + 1}</div>
                        ))}
                    </div>
                }
                {columnsPerRow.map((numberOfColumns, rowIndex) => {
                    const effectiveColumnsLeft = (rowIndex > 0 && mergedRowsLeft[rowIndex]) ? columnsPerRow[rowIndex - 1] : numberOfColumns;
                    const effectiveColumnsRight = (rowIndex > 0 && mergedRowsRight[rowIndex]) ? columnsPerRow[rowIndex - 1] : numberOfColumns;
                    return (
                        <div key={`table-row-${rowIndex}-${numberOfColumns}`} className="table-row" style={{ height: 'calc(100%/' + (defaultColumns.length + 0.48) + ')' }}>
                            <div className="index-cell">{rowIndex + 1}</div>
                            {columns === 2 ?
                                <>
                                    <div className="cell-group">
                                        {Array.from({ length: effectiveColumnsLeft }).map((_, colIndex) => (
                                            <div
                                                key={`cell-group-cell-${colIndex}`}
                                                className="cell"
                                                style={{
                                                    borderBottom: (rowIndex < mergedRowsLeft.length && rowIndex >= 0 && mergedRowsLeft[rowIndex - 1]) ? "none" : '1px solid black',
                                                }}
                                            ></div>
                                        ))}
                                    </div>
                                    <div className="cell-group">
                                        {Array.from({ length: effectiveColumnsRight }).map((_, colIndex) => (
                                            <div
                                                key={`key-cell-${colIndex}`}
                                                className="cell"
                                                style={{
                                                    borderBottom: (rowIndex < mergedRowsRight.length && rowIndex >= 0 && mergedRowsRight[rowIndex - 1]) ? "none" : '1px solid black',
                                                }}
                                            ></div>
                                        ))}
                                    </div>
                                </>
                                :
                                Array.from({ length: columnsPerRow[rowIndex] }).map((_, colIndex) => (
                                    <div
                                        key={`cell-${colIndex}`}
                                        className="cell"
                                        style={{
                                            borderTop: (mergedRows[rowIndex - 1]) ? "none" : '1px solid black',
                                            borderBottom: (rowIndex < mergedRows.length - 1 && rowIndex >= 0 && mergedRows[rowIndex]) ? "none" : '1px solid black'
                                        }}
                                    ></div>
                                ))}
                        </div>
                    )
                })}
            </div>
            {columns === 2 ? (
                <div className="select-container" style={{ marginTop: '8px' }}>
                    {columnsPerRow.map((numberOfColumns, rowIndex) => (
                        <div key={`checkbox-select-row-${rowIndex}`} className="select-row" style={{ height: 'calc(100%/' + (defaultColumns.length + 0.48) + ')' }}>
                            {rowIndex < 19 &&
                                <input
                                    type="checkbox"
                                    checked={!mergedRowsRight[rowIndex]}
                                    onChange={(event) => handleCheckboxChange(rowIndex, 'right', event)}
                                />
                            }
                        </div>
                    ))}
                </div>
            ) :
                <div className="select-container">
                    <div style={{ position: 'absolute', top: '64px' }}>
                        <p style={{ color: 'gray', fontSize: '12px', }}>
                            {t("modals.configurator.adjustNumberOfCells")}
                            </p>
                    </div>
                    {columnsPerRow.map((numberOfColumns, rowIndex) => (
                        <div key={`select-select-row-${rowIndex}`} className="select-row" style={{ height: 'calc(100%/' + (defaultColumns.length + 0.48) + ')' }}>
                            {rowIndex < 19 &&
                                <select
                                    value={
                                        mergedRows[rowIndex]
                                            ? 'X'
                                            : numberOfColumns
                                    }
                                    className="select"
                                    onChange={(event) => handleSelectChange(rowIndex, event)}
                                >
                                    {(mergedRowsLeft[rowIndex] && rowIndex > 0) || (mergedRowsRight[rowIndex] && rowIndex > 0) ? (
                                        <option value={numberOfColumns}>{numberOfColumns}</option>
                                    ) : (
                                        <>
                                            {(mergedRows[rowIndex] && rowIndex > 0) || (mergedRows[rowIndex - 1] && rowIndex > 0) ? <option value={numberOfColumns}>{numberOfColumns}</option> : <>
                                                {options.map((option, index) => (
                                                    <option key={`option-${index}`} value={option}>{option}</option>
                                                ))}</>}
                                        </>
                                    )}
                                    <option value="X">X</option>
                                </select>
                            }
                        </div>
                    ))}
                </div>
            }

        </div>
    );
};

export default DrumConfiguratorPage;
