import React from "react";
import { useTranslation } from "react-i18next";
import {
	FormControlLabel,
	Radio,
	RadioGroup,
	FormControl,
	
} from "@mui/material";
import "./RadioButtons.css";

const RadioImagesGroup = ({ id, machineType, handleChange, isValid=true }) => {
	const { t } = useTranslation();

	const models = [
		{ value: "D810", image: "/D810.png", label: "D810" },
		{ value: "D1080", image: "/D1080.png", label: "D1080" },
		{ value: "L40", image: "/L40.png", label: "L40" },
		{ value: "F80", image: "/F80.png", label: "F80" },
	];

	const handleImageClick = (value) => {
		handleChange({ target: { value } });
	};

	return (
		<div className="radio-input-box image-radio-input">
			<FormControl component="fieldset">
				<RadioGroup
					row
					aria-label="model"
					name="model"
					value={machineType}
					onChange={handleChange}
				>
					{models.map((model) => (
						<div className="radio-with-image" key={model.value}>
							<img
								src={process.env.PUBLIC_URL + model.image}
								alt={model.label}
								onClick={() => handleImageClick(model.value)}
							/>
							<FormControlLabel
								className="radio-image-label"
								value={model.value}
								control={
									<Radio
										checked={machineType === model.value}
										name={id}
										id={id}
										sx={{
											color: !isValid ? "#AF3732" : "",
										}}
									/>
								}
								label={<span>{model.label}</span>}
							/>
						</div>
					))}
				</RadioGroup>
				{!isValid && (
					<span className="error-message">
						{t("global.validation.radio.required")}
					</span>
				)}
			</FormControl>
		</div>
	);
};

export default RadioImagesGroup;
