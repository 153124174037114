import React, { useState } from "react";
import "./Header.css";
import { useTranslation } from "react-i18next";

const Header = () => {
	const [selectedLanguage, setSelectedLanguage] = useState("pl");

	const { t, i18n } = useTranslation();

	const handleLanguageChange = (event) => {
		const selectedLang = event.target.value;
		setSelectedLanguage(selectedLang);
		i18n.changeLanguage(selectedLang);
	};

	return (
		<header>
			<div className="header-center">
				<img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" />
				<h1>{t("header.title")}</h1>
				<select
					className="language-select"
					value={selectedLanguage}
					onChange={handleLanguageChange}
				>
					<option value="pl">PL</option>
					<option value="en">EN</option>
					<option value="de">DE</option>
				</select>
			</div>
		</header>
	);
};

export default Header;
